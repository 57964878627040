import { message } from "antd";
import React, { createContext, useContext } from "react";

const MainContext = createContext();

const MainContextProvider = ({ children = null }) => {
  const [state, setState] = React.useState(
    localStorage.getItem("local_context")
      ? JSON.parse(localStorage.getItem("local_context"))
      : {}
  );

  const setContext = (updatedState = {}) => {
    const newState = { ...state, ...updatedState };
    localStorage.setItem("local_context", JSON.stringify(newState));
    setState(newState);
  };

  const getContext = (key = "") => {
    if (key) return state[key];
    else return state;
  };

  const clearContext = () => {
    localStorage.clear();
    setState({});
    message.success("Logged out successfully");
  };

  return (
    <MainContext.Provider value={{ getContext, setContext, clearContext }}>
      <div className="wrapper">{children}</div>
    </MainContext.Provider>
  );
};

const useMainContext = () => useContext(MainContext);

export { MainContext, MainContextProvider, useMainContext };

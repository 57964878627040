import { useEffect, useState } from "react";
import { Button, Card, Input, Flex, Row, Col, Collapse } from "antd";

import CompanySelect from "../components/CompanySelect";

import "./styles/form.scss";

const getDateNum = (num) => {
  if (num < 10) return `0${num}`;
  return num;
};
const currentdate = new Date();
const datetime =
  getDateNum(currentdate.getDate()) +
  "." +
  getDateNum(currentdate.getMonth() + 1) +
  "." +
  currentdate.getFullYear() +
  " @ " +
  currentdate.getHours() +
  ":" +
  currentdate.getMinutes();

const Step1 = ({ step, setStep, data, updateData }) => {
  return (
    <div>
      <Row gutter={10}>
        <Col span={6}>
          <h3>Date & Time</h3>
          <Input size="large" value={datetime} placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Serial No</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Customer</h3>
          <Input size="large" value={data?.company} placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Customer Invoice No</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>DO/ GRN No</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Customer Invoice Date</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Customer Instruction</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Material from</h3>
          <Input size="large" placeholder="Supplier Name" />
        </Col>
        <Col span={6}>
          <h3>Remark</h3>
          <Input size="large" placeholder="Supplier Name" />
        </Col>
      </Row>
      <Flex gap={10} style={{ marginTop: 20 }}>
        <Button onClick={() => setStep(step - 1)}>Back</Button>
        <Button onClick={() => setStep(step + 1)}>Next</Button>
      </Flex>
    </div>
  );
};
const Step2 = ({ step, setStep, data, updateData }) => {
  const [finalProductList, setFinalProductList] = useState([]);
  const ProductForm = ({ data }) => {
    return (
      <Row gutter={10}>
        <Col span={6}>
          <h3>Serial No</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Product Name</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Batch No/ Lot No</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Stock Qty</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Stock UOM</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Billing Qty</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Billing UOM</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Net weight in kg</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Gross weight in kg</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
      </Row>
    );
  };
  const productList = data?.productList || [{}];

  useEffect(() => {
    let tempProductList = [];
    productList.forEach((product, i) => {
      tempProductList.push({
        key: i + 1,
        label: `Product ${i + 1}`,
        children: <ProductForm data={product} />,
      });
    });
    setFinalProductList([...tempProductList]);
  }, [data?.productList]);

  return (
    <div>
      <Button
        style={{ marginBottom: 20 }}
        onClick={() => {
          setFinalProductList([
            ...finalProductList,
            {
              key: finalProductList.length + 1,
              label: `Product ${finalProductList.length + 1}`,
              children: <ProductForm data={{}} />,
            },
          ]);
        }}
      >
        Add Product
      </Button>
      <Collapse accordion items={finalProductList} />
      <Flex gap={10} style={{ marginTop: 20 }}>
        <Button onClick={() => setStep(step - 1)}>Back</Button>
        <Button onClick={() => setStep(step + 1)}>Next</Button>
      </Flex>
    </div>
  );
};
const Step3 = ({ step, setStep, data, updateData }) => {
  return (
    <div>
      <Row gutter={10}>
        <Col span={6}>
          <h3>Transporter Name</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Vehicle Type</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Vehicle Number</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Driver Name</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Driver Mobile Number</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Tempo Charges</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>LR No</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>LR Date</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>LR Charges Paid</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
      </Row>
      <Flex gap={10} style={{ marginTop: 20 }}>
        <Button onClick={() => setStep(step - 1)}>Back</Button>
        <Button>Submit</Button>
      </Flex>
    </div>
  );
};

function Form() {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});

  const updateData = (key, value) => {
    let obj = data;
    obj[key] = value;
    setData({ ...obj });
  };

  const props = { step, setStep, data, updateData };
  const steps = {
    0: <CompanySelect {...props} />,
    1: <Step1 {...props} />,
    2: <Step2 {...props} />,
    3: <Step3 {...props} />,
  };

  return (
    <Card
      bordered
      className="form-wrapper"
      size={window.innerWidth < 767 ? "small" : "default"}
    >
      <h2>Step {step + 1}</h2>
      {steps[step]}
    </Card>
  );
}

export default Form;

import React from "react";
import { Card, Select, Button, message } from "antd";

import "./styles/company.scss";

const companyOptions = ["ABC", "DEF", "GHI", "XYZ"];
function CompanySelect({ step, data, setStep, updateData }) {
  return (
    <Card
      bordered
      className="company-select-wrapper"
      size={window.innerWidth < 767 ? "small" : "default"}
    >
      <div className="content-wrapper">
        <Select
          size="large"
          style={{ minWidth: 400 }}
          placeholder="Select Comapany"
          onChange={(val) => updateData("company", val)}
          value={data.company}
        >
          {companyOptions.map((com) => (
            <Select.Option key={com}>{com}</Select.Option>
          ))}
        </Select>
        <Button
          onClick={() => {
            if (data?.company) setStep(step + 1);
            else message.error("Please select company");
          }}
        >
          Proceed
        </Button>
      </div>
    </Card>
  );
}

export default CompanySelect;

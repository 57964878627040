import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Button } from "antd";

import "./styles/sidenav.scss";

const buttons = [
  { name: "Inward", path: "/" },
  { name: "Outward", path: "/OutwardForm" },
];
function SideNav() {
  const nav = useNavigate();
  const { pathname } = useLocation();

  return (
    <aside>
      {buttons.map((btn = {}) => (
        <Button
          className={pathname === btn.path ? "active" : ""}
          onClick={() => nav(`${btn.path}`)}
        >
          {btn.name}
        </Button>
      ))}
    </aside>
  );
}

export default SideNav;

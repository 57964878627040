import { ConfigProvider } from "antd";
import Main from "./pages";
import { MainContextProvider } from "./utils/MainContext";

function App() {
  return (
    <ConfigProvider>
      <MainContextProvider>
        <Main />
      </MainContextProvider>
    </ConfigProvider>
  );
}

export default App;

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SideNav from "../components/SideNav";
import Form from "./Form";
import Login from "./Login";
import OutwardForm from "./OutwardForm";
import { useMainContext } from "../utils/MainContext";
import { Button } from "antd";

import "./styles/header.scss";

const routes = [
  { label: "Form", path: "/", component: Form },
  {
    label: "OutwardForm",
    path: "/OutwardForm",
    component: OutwardForm,
  },
];

function Main() {
  const { getContext, clearContext } = useMainContext();

  return (
    <BrowserRouter>
      <header className="header">
        <span>Akash Storage</span>
        {getContext("isLoggedIn") && (
          <Button danger type="text" size="large" onClick={clearContext}>
            LogOut
          </Button>
        )}
      </header>
      <main>
        {getContext("isLoggedIn") ? (
          <>
            <SideNav />
            <section>
              <Routes>
                {routes.map((route = {}) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
              </Routes>
            </section>
          </>
        ) : (
          <Login />
        )}
      </main>
    </BrowserRouter>
  );
}

export default Main;

import { useState } from "react";
import { Button, Card, Input, Flex, Row, Col } from "antd";

import CompanySelect from "../components/CompanySelect";
import "./styles/form.scss";

const Step1 = ({ step, setStep }) => {
  return (
    <div>
      <Row gutter={10}>
        <Col span={6}>
          <h3>Form No</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>DO/ INV no</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Godown No</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Dispatch Date</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Transporter Name</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Vehicle Type</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Vehicle Number</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Driver Name</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Driver Mobile Number</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>Tempo Charges</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>LR No</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>LR Date</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
        <Col span={6}>
          <h3>LR Charges Paid</h3>
          <Input size="large" placeholder="placeholder" />
        </Col>
      </Row>
      <Flex gap={10} style={{ marginTop: 20 }}>
        <Button onClick={() => setStep(step - 1)}>Back</Button>
        <Button onClick={() => setStep(step + 1)}>Next</Button>
      </Flex>
    </div>
  );
};
const Step2 = ({ step, setStep }) => {
  return (
    <div>
      <div>
        <h3>Label</h3>
        <Input size="large" placeholder="placeholder" />
      </div>
      <div>
        <h3>Label</h3>
        <Input size="large" placeholder="placeholder" />
      </div>
      <Flex gap={10} style={{ marginTop: 20 }}>
        <Button onClick={() => setStep(step - 1)}>Back</Button>
        <Button onClick={() => setStep(step + 1)}>Next</Button>
      </Flex>
    </div>
  );
};
const Step3 = ({ step, setStep }) => {
  return (
    <div>
      <div>
        <h3>Label</h3>
        <Input size="large" placeholder="placeholder" />
      </div>
      <div>
        <h3>Label</h3>
        <Input size="large" placeholder="placeholder" />
      </div>
      <Flex gap={10} style={{ marginTop: 20 }}>
        <Button onClick={() => setStep(step - 1)}>Back</Button>
        <Button onClick={() => setStep(step + 1)}>Next</Button>
      </Flex>
    </div>
  );
};
const Step4 = ({ step, setStep }) => {
  return (
    <div>
      <div>
        <h3>Label</h3>
        <Input size="large" placeholder="placeholder" />
      </div>
      <div>
        <h3>Label</h3>
        <Input size="large" placeholder="placeholder" />
      </div>
      <Flex gap={10} style={{ marginTop: 20 }}>
        <Button onClick={() => setStep(step - 1)}>Back</Button>
        <Button>Submit</Button>
      </Flex>
    </div>
  );
};

function OutwardForm() {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});

  const updateData = (key, value) => {
    let obj = data;
    obj[key] = value;
    setData({ ...obj });
  };

  const props = { step, setStep, data, updateData };
  const steps = {
    0: <CompanySelect {...props} />,
    1: <Step1 {...props} />,
    2: <Step2 {...props} />,
    3: <Step3 {...props} />,
    4: <Step4 {...props} />,
  };

  return (
    <Card
      bordered
      className="form-wrapper"
      size={window.innerWidth < 767 ? "small" : "default"}
    >
      <h2>Step {step + 1}</h2>
      {steps[step]}
    </Card>
  );
}

export default OutwardForm;

import { useEffect, useState } from "react";
import { message } from "antd";
import axios from "axios";

export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://bbot.geniesignal.com/api";

export const API_CALL = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  timeout: 300000,
  headers: { "Content-Type": "application/json" },
  validateStatus: (status) => status > 100,
  transformRequest: [(data) => JSON.stringify(data)],
});

API_CALL.interceptors.request.use((config) => {
  if (localStorage.getItem("isLoggedIn")) {
  }
  return config;
});

API_CALL.interceptors.response.use((res) => {
  if (res.status === 401 || res.status === 403) {
    message.error(res.data.error ? res.data.message : "Session Expired !");
    localStorage.clear();
    window.location.reload();
  } else if (res.status > 400 || res.data.error) {
    message.error(res.data?.message || "Something went wrong");
  }
  return res.data || {};
});

export const defaultParamObj = { page: 1, per_page: 10 };

export const useGetListApi = ({
  endPoint = "",
  listKey = "data",
  params = defaultParamObj,
  initiateOnLoad = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [paramObj, setParamObj] = useState(params);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (initiateOnLoad) getList();
    return () => resetState();
  }, []);

  const getList = async (params = paramObj) => {
    if (!endPoint || loading) return;
    setLoading(true);
    setParamObj(params);
    delete params.total;
    API_CALL.get(endPoint, { params })
      .then((res) => {
        if (res.status === "success" && res.data?.[listKey]?.length) {
          setList(res.data[listKey]);
          setParamObj({ ...params, total: res.data.total || 0 });
        } else resetState();
        setLoading(false);
      })
      .catch(() => resetState());
  };

  const resetState = () => {
    setLoading(false);
    setList([]);
    setParamObj(defaultParamObj);
  };

  return { loading, paramObj, getList, list, setList };
};

import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";

import { API_CALL } from "../utils/Network";
import { useMainContext } from "../utils/MainContext";

import "./styles/login.scss";

function Login() {
  const { setContext } = useMainContext();
  const [loading, setLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");

  const loginHandler = (values = {}) => {
    if (values.username === "vikasgaud" && values.password === "q1w2e3r4")
      return setContext({ isLoggedIn: true });
    else return setFormErrorMessage("Please check your Username & Password !");
    setLoading(true);
    const data = { username: values.username, password: values.password };
    API_CALL.post("/login", data)
      .then((response = {}) => {
        if (response.status === "success") {
          message.success(response.message || "Logged in successfully");
          setContext({ isLoggedIn: true }); // token_object: response.data.data
        } else setFormErrorMessage("Please check your Username & Password !");
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <div className="login-form">
      <h4>Login</h4>
      <Form
        layout="vertical"
        onFinish={loginHandler}
        initialValues={{ remember: true }}
        style={{ width: "100%" }}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please enter your username!" }]}
        >
          <Input placeholder="Enter your username" type="text" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please enter your password!" }]}
        >
          <Input.Password placeholder="Enter your password" type="password" />
        </Form.Item>
        {formErrorMessage && (
          <div className="error-message-box">{formErrorMessage}</div>
        )}
        <Form.Item>
          <Button block type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Login;
